let menuItem = [
  {
    url: "/nurse/home",
    name: "nurse-dashboard",
    icon: "HomeIcon",
    slug: "home",
    i18n: "Home"
  },
  {
    url: "/nurse/notice-board",
    name: "Dashboard",
    icon: "MonitorIcon",
    slug: "dashboard",
    i18n: "Notice Board"
  },
  ...["NZ", 'AU'].includes(process.env.VUE_APP_REGION) ? [
    {
      url: "/nurse/shop",
      name: "Shop",
      icon: "ShoppingBagIcon",
      slug: "shop",
      permission:'Shop',
      i18n: "Products"
    },
    {
      url: "/nurse/orders",
      name: "My Orders",
      slug: "orders",
      i18n: "My Orders",
      permission:'Shop',
      icon: "TruckIcon"
    },
    {
      url: "/nurse/patients",
      name: "Patient Management",
      icon: "FolderPlusIcon",
      slug: "pages-register-nurse",
      i18n: "Patients",
    },
    {
      url: "/nurse/treatment-history",
      name: "Treatment History",
      icon: "ActivityIcon",
      slug: "treatment-history",
      i18n: "Treatments",
    },
    {
      url: "/nurse/invoices",
      name: "Invoice Management",
      slug: "invoice",
      i18n: "Invoices",
      icon: "DollarSignIcon",
      permission:'Invoice'
    },
    {
      url: '/nurse/pos',
      name: "POS",
      slug: "pos",
      i18n: "POS",
      permission: 'POS',
      icon: "DollarSignIcon",
    },
    {
      url: "/nurse/insights",
      name: "Insights",
      icon: "BarChart2Icon",
      permission:'Insights',
      slug: "insights",
      i18n: "Insights",
    },
    {
      url: "/nurse/referral",
      name: "Refer Someone to Fresh",
      slug: "referral",
      i18n: "Refer Someone to Fresh",
      icon: "UserPlusIcon"
    },
    {
      header: "Resource Hub",
      i18n: "Resource Hub",
      items: [
        {
          url: "/nurse/resources",
          name: "Resources",
          icon: "CodepenIcon",
          slug: "resources",
          i18n: "Resources",
        }
      ]
    },
    {
      header: "Training Hub",
      i18n: "Training Hub",
      items: [
        {
          url: "/nurse/events",
          name: ['AU'].includes(process.env.VUE_APP_REGION) ? "Webinars & Training": "Events", // Applies to AU only for now
          icon: "CalendarIcon",
          slug: "events",
          i18n: ['AU'].includes(process.env.VUE_APP_REGION) ? "Webinars & Training": "Events" // Applies to AU only for now
        },
        // Applies to AU only
        ['AU'].includes(process.env.VUE_APP_REGION) && {
          url: "/nurse/supplier-training",
          name: 'Supplier Training',
          icon: "CalendarIcon",
          slug: "supplier-training",
          i18n: 'Supplier Training'
        }
      ]
    },
    {
      header: "Compliance",
      permission: 'Compliance',
      i18n: "Compliance",
      items: [
        {
          url: "/nurse/drugbook",
          name: "Drugbook",
          icon: "BookIcon",
          slug: "drugbook",
          i18n: "Drugbook",
          permission:'Drug'
        },
        {
          url: "/nurse/inventory",
          name: "Inventory",
          featherIcon: false,
          icon: "kitchen",
          slug: "inventory",
          i18n: "Inventory",
          permission: "Drug",
        },
        {
          url: "/nurse/compliance",
          name: "Compliance",
          icon: "GridIcon",
          slug: "compliance",
          i18n: "Compliance Checks",
          permission:'Compliance'
        },
      ]
    }
  ] : [
    {
      url: "/nurse/patients",
      name: "Patient Management",
      icon: "FolderPlusIcon",
      slug: "pages-register-nurse",
      i18n: "Patients",
    },
    {
      url: "/nurse/treatment-history",
      name: "Treatment History",
      icon: "ActivityIcon",
      slug: "treatment-history",
      i18n: "Treatments",
    },
    {
      url: "/nurse/referral",
      name: "Refer Someone to Fresh",
      slug: "referral",
      i18n: "Refer Someone to Fresh",
      icon: "UserPlusIcon"
    },
    {
      url: "/nurse/resources",
      name: "Resources",
      icon: "CodepenIcon",
      slug: "resources",
      i18n: "Resources",
    },
    {
      url: "/nurse/invoices",
      name: "Invoice Management",
      slug: "invoice",
      i18n: "Invoices",
      icon: "DollarSignIcon",
      permission:'Invoice'
    },
    {
      url: "/nurse/drugbook/entries",
      name: "Drugbook",
      icon: "BookIcon",
      slug: "drugbook",
      i18n: "Drugbook",
      permission:'Drug'
    },
    {
      url: "/nurse/inventory",
      name: "Inventory",
      featherIcon: false,
      icon: "kitchen",
      slug: "inventory",
      i18n: "Inventory",
      permission: "Drug",
    },
    {
      url: "/nurse/compliance",
      name: "Compliance",
      icon: "GridIcon",
      slug: "compliance",
      i18n: "Compliance Checks",
      permission:'Compliance'
    },
    {
      url: "/nurse/shop",
      name: "Shop",
      icon: "ShoppingBagIcon",
      slug: "shop",
      permission:'Shop',
      i18n: "Shop"
    },
    {
      url: "/nurse/orders",
      name: "My Orders",
      slug: "orders",
      i18n: "My Orders",
      permission:'Shop',
      icon: "TruckIcon"
    },
    {
      url: '/nurse/pos',
      name: "POS",
      slug: "pos",
      i18n: "POS",
      permission: 'POS',
      icon: "DollarSignIcon",
    },
    {
      url: "/nurse/consent-forms",
      name: "Consent Forms",
      icon: "ActivityIcon",
      slug: "consent-forms",
      i18n: "Consent Forms",
      permission: 'CustomConsentForm',
    },
  ],
  {
    header: "Settings",
    icon: "SettingsIcon",
    i18n: "Settings",
    permission:'Settings',
    items: [
      {
        url: "/nurse/clinics",
        name: "Clinics",
        icon: "HomeIcon",
        slug: "clinics",
        i18n: "Clinics",
      },
      {
        url: "/nurse/staff",
        name: "Staff",
        icon: "UsersIcon",
        slug: "staff",
        i18n: "Staff",
      },
      ...['NZ', 'AU'].includes(process.env.VUE_APP_REGION) ? [
        {
          url: "/nurse/consent-forms",
          name: "Consent Forms",
          icon: "ActivityIcon",
          slug: "consent-forms",
          i18n: "Consent Forms",
          permission: 'CustomConsentForm',
        }
      ] : [],
      {
        url: "/nurse/settings/products-list",
        name: "nurse Products List",
        slug: "nurse-products-list",
        icon: "CheckSquareIcon",
        i18n: "Products List"
      },
      {
        url: "/nurse/settings/custom-products-list",
        name: "nurse Custom Products List",
        slug: "nurse-custom-products-list",
        icon: "CheckSquareIcon",
        i18n: "Products List",
        permission: 'CustomProduct',
      },
      {
        url: "/nurse/settings/treatment-areas",
        name: "nurse Treatment Areas",
        slug: "nurse-treatment-areas",
        icon: "ShuffleIcon",
        i18n: "Treatment Areas"
      },
    ]
  }
];

export default menuItem;
